@import "bootstrap/scss/bootstrap.scss";
@import "bootstrap-icons/font/bootstrap-icons.css";

@font-face {
  font-family: "Poppins";
  src: url(/fonts/Poppins/Poppins-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Naston Regular";
  src: url(/fonts/naston-webfont/naston-regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url(/fonts/Inter/static/Inter-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  --primary-color: #1770b5;
  --secondary-color: #fcf7f8;
  --text-primary-color: #fff;
  --text-secondary-color: #8f8f8f;
  --bg-primary-color: #171717;
  --bg-secondary-color: #1c1c1c;
  --bg-tertiary-color: #1f1f1f;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.5rem;
}

html {
  overflow-y: scroll;
  height: 100%;
}

body {
  overflow-y: visible;
  position: relative;
  height: unset;
  font-family: "Poppins";
  background-color: var(--bg-primary-color) !important;
  color: var(--text-primary-color) !important;
}

html,
body {
  overflow-x: hidden;
  margin: 0;
}

section {
  background-image: url("/assets/images/gridlines.png");
  background-repeat: repeat;
  background-size: 100%;
}
