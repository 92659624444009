/* The sidepanel menu */
.sidepanel {
  background-color: var(--bg-secondary-color);
  transition: all 0.5s ease-in-out;
  height: 100vh;
  width: 0%;
  position: fixed;
  z-index: 9999;
  right: 0;
  top: 0;
  overflow: hidden;

  .smenu {
    text-decoration: none;
    transition: 0.3s;
    color: var(--text-primary-color);
    font-family: "Inter";
    font-size: 2.5rem;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 4.5rem 2rem 0;

    &:hover {
      color: var(--primary-color);
    }
  }

  .closebtn {
    position: absolute;
    top: 0.5rem;
    right: 3.75rem;
    img {
      height: 70px;
      width: 70px;
    }
  }

  .sidebar-brand {
    position: absolute;
    top: 1.5rem;
    left: 3rem;
    text-decoration: none;
    text-transform: uppercase;
    h1 {
      font-size: 2rem;
      font-family: "Naston Regular";
      color: var(--primary-color);
    }
  }

  .sidepanel-section-2 {
    .schedule-button {
      width: 200px;
      font-weight: 500;
      background-color: var(--primary-color);
      border-radius: 22px;
      &:hover {
        border: 2px solid var(--primary-color);
        background-color: transparent;
        color: white !important;
      }
    }
  }
}

.overlay {
  height: 100%;
  width: 0%;
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  background-color: #202020e0;
  overflow-x: hidden;
  transition: 0.5s;
}
