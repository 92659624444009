#what-sets-us-apart {
  .apart-container {
    background-image: url("/assets/images/blue-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

#our-team {
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
}

#our-founder {
  .container {
    background-color: var(--bg-secondary-color);
  }
}

#our-cofounders {
  .container {
    .cofounder-card {
      background-color: var(--bg-secondary-color);
      img {
        size: contain;
        height: 350px;
      }
    }
  }
}

#work-with-us {
  .container {
    background-image: url("/assets/images/blue-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    .share-your-cv-btn {
      background-color: transparent;
      border: 2px solid var(--primary-color);
      &:hover {
        background-color: var(--primary-color);
        border: 2px solid var(--primary-color);
      }
    }
  }
}
