.get-in-touch-section {
  h1 {
    font-weight: 500;
    font-size: 6.5rem;
    margin-bottom: 1rem;
  }
  .btn {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #222;
      color: var(--primary-color);
    }
  }
}
