.viewServiceBtn {
  &:hover {
    color: white !important;
  }
}

#services-section {
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }
}
.services-detail-card {
  background: linear-gradient(
    213deg,
    rgba(51, 51, 51, 0.09) 0%,
    rgba(51, 51, 51, 0.9) 100%
  );
}
