.navbar {
  background-color: transparent;
  .nav-link {
    padding: 1rem 1.5rem;
    margin-left: 2rem;
    color: #fff;
    font-weight: 500;
    &:hover {
      color: var(--primary-color);
    }
    &.active {
      color: var(--primary-color);
    }
  }
  .schedule-button {
    font-weight: 500;
    background-color: var(--primary-color);
    border-radius: 22px;
    border: 2px solid var(--primary-color);
    &:hover {
      border: 2px solid var(--primary-color);
      background-color: transparent;
      color: white !important;
    }
  }
  .sidebar-btn {
    img {
      height: 75px;
      width: 75px;
    }
  }
  &.stickyadd {
    background-color: #fff;
    box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
    .nav-link {
      color: #000 !important;
      &.active {
        color: var(--primary-color) !important;
      }
    }
    .schedule-button {
      background-color: var(--primary-color) !important;
      border: 2px solid var(--primary-color) !important;
      color: #fff !important;
      &:hover {
        border: 2px solid var(--primary-color) !important;
        background-color: transparent !important;
        color: var(--primary-color) !important;
      }
    }
  }
  &.sticky {
    .ml-auto {
      margin-left: auto;
    }
  }
  .sideMenuBtn {
    height: 70px !important;
    width: 70px !important;
  }
}
