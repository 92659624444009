.blue-element {
  position: absolute;
  top: 100px;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/assets/images/blue-element.png");
  background-repeat: no-repeat;
  background-size: 500px;
  z-index: -1;
}

.hero-section {
  background-image: linear-gradient(
      270deg,
      rgba(17, 17, 17, 0) 10.19%,
      rgba(17, 17, 17, 0.89) 50.08%,
      #111 99.66%
    ),
    url("/assets/images/bg-animation.gif");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  mix-blend-mode: lighten;
  position: relative;
  z-index: 1;

  .hero-social-vector-1 {
    position: absolute;
    bottom: 110px;
    left: -5px;
    height: 200px;
  }
  .hero-social-vector-2 {
    position: absolute;
    top: 110px;
    left: 5px;
    height: 200px;
    rotate: 180deg;
  }

  .hero-social-icons {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(-50%, -50%);
    .social-icon {
      color: var(--text-primary-color);
      font-size: 18px;
    }
  }
  .hero-content {
    .hero-title {
      font-family: "Naston Regular";
      font-weight: 400;
      font-size: 6rem;
      text-transform: uppercase;
      color: #fff;
      margin-bottom: 1rem;
    }
    .hero-subtitle {
      font-family: "Inter";
      font-weight: 400;
      font-size: 1.875rem;
      color: #fff;
      margin-bottom: 1rem;
    }
    .hero-divider1 {
      position: absolute;
      right: calc(50% - 225px);
      bottom: calc(50% - 125px);
      width: 400px;
    }
    .hero-description {
      position: absolute;
      height: 100px;
      width: 400px;
      background-image: url("/assets/images/dotted-pattern-bg.png");
      background-repeat: no-repeat;
      background-size: contain;
      right: calc(50% - 200px);
      bottom: calc(50% - 250px);
      font-size: 12px;
      color: var(--text-secondary-color);
    }
    .hero-divider-two {
      position: absolute;
      left: calc(50% - 600px);
      bottom: calc(50% - 250px);
      width: 400px;
    }
    .hero-description-two {
      position: absolute;
      height: 100px;
      width: 400px;
      background-image: url("/assets/images/dotted-pattern-bg.png");
      background-repeat: no-repeat;
      background-size: contain;
      left: calc(50% - 650px);
      bottom: calc(50% - 350px);
      font-size: 12px;
      color: var(--text-secondary-color);
    }
  }
}

#brands {
  background-color: var(--bg-tertiary-color);
  img {
    width: 150;
    height: 150;
    object-fit: contain;
    filter: grayscale(100%);
    &:hover {
      filter: grayscale(0%);
    }
  }
}

.feature {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  height: 4rem;
  width: 4rem;
  font-size: 2rem;
}

.testimonial-section {
  .testimonial {
    .testimonial-text {
      font-family: "Inter";
      height: 400px;
      background-color: #1c1c1c;
      font-weight: 400;
      font-size: 1.875rem;
      color: #fff;
      h1 {
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
      }
      h5 {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
      }
      p {
        font-size: 1.2rem;
        font-weight: 400;
        margin-bottom: 1rem;
      }
    }
  }
}

#founded-section {
  .container {
    background-image: url("/assets/images/founded-img.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

#projects-section {
  margin: 0px 3rem;
  padding: 0px 3rem;
  .project-card {
    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
    }
  }
}

#connect-to-us {
  .container {
    background-image: url("/assets/images/blue-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    .share-your-query-btn {
      background-color: transparent;
      border: 2px solid var(--primary-color);
      &:hover {
        background-color: var(--primary-color);
        border: 2px solid var(--primary-color);
      }
    }
  }
}

#message {
  background-color: var(--bg-secondary-color);
}
